import client from './index'

    export interface EvCarProfile {
        place_id?: string;
        model_id?: number;
        model_make_id: string;
        model_name: string;
        model_year: number;
        model_trim?: string;
        battery_capacity: number;
        charging_connector_type: string;
            // | 'Type 1 (J1772)'
            // | 'Type 2 (Mennekes)'
            // | 'CHAdeMO'
            // | 'CCS (Combined Charging System)'
            // | 'Tesla Supercharger';
        charging_time: number;
        range_per_charge: number;
        price_per_minute_ev: number;
        price_per_km_ev: number;
        price_per_minute_up_to_100_km_ev: number;
        price_per_minute_above_100_km_ev: number;
        pause_price_ev: number;
        deposit_amount_ev: number;
        car_type: string;
        address: string;
        transmission: 'automatic';
        fuel_type: 'electric';
        no_branded_title: boolean;
        identity_country: string;
        plate_number: string;
        car_description: string;
        charging_included?: boolean;
        battery_included?: boolean;
        charging_policy?: string;
        status?: string;
        user_id: string;
        gps?: boolean;
        air_conditioner?: boolean;
        seats?: number;
        doors?: number;
        bluetooth_stereo?: boolean;
        wifi_internet?: boolean;
        fire_extinguisher?: boolean;
        ownership_permit?: string[];
    }
    

    export interface ApiResponse<T> {
        message: string;
        status: string;
        code: number;
        data: T;
        id?: string;
    }

    export interface ApiError {
        message: string;
        status: string;
        code: number;
        errors?: Record<string, unknown>;
    }

    export interface UploadedImage {
        file: File;
        preview: string;
      }

    // export const createEvCarProfile = async (data: Omit<EvCarProfile, 'id'>) => {
    //     return client.post<ApiResponse<EvCarProfile>>('/cars/ev', data);
    // };
    export const createEvCarProfile = async (data: Omit<EvCarProfile, 'id'>) => {
        return client.post<ApiResponse<{ id: string }>>('/cars/ev', data);
    };
      
      

    export const getEvCarProfiles = async (params: Record<string, any>) => {
        return client.get<ApiResponse<EvCarProfile[]>>('/cars/ev', { params });
    };

    export const updateEvCarProfile = async (id: string, data: FormData) => {
        
        console.log("🚀 ~ updateEvCarProfile ~ data:", data)
        return client.put<ApiResponse<EvCarProfile>>(`/cars/ev/${id}`, data, {
        headers: {
            'Content-Type': 'multipart/form-data',
            },
        });
        
    };
      

    export const deleteEvCarProfile = async (id: string) => {
        return client.delete<ApiResponse<null>>(`/cars/ev/${id}`);
    };
    export const deleteEvCarImage = (carId: string, imageId: string) => {
        return client.delete<ApiResponse<null>>(`/cars/ev/${carId}/images/${imageId}`);
    };
    
    export const uploadEvCarImage = async (carId: string, file: File) => {
        const formData = new FormData();
        formData.append('image', file);
        return client.post<ApiResponse<{ secure_url: string; public_id: string }>>(`/cars/ev/${carId}/images`, formData);
    };
    
    export const getEvCarImages = async (carId: string) => {
        return client.get<ApiResponse<{ secure_url: string; public_id: string }[]>>(`/cars/ev/${carId}/images`);
    };